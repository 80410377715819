import * as Sentry from '@sentry/nextjs';

try {
  Sentry.init({
    dsn: 'https://3ae6226874734e53b117b7f51ba1c87c@o51327.ingest.sentry.io/5990455',
    debug: true,
    environment: 'dev',
  });
} catch (error) {
  console.error(error);
}